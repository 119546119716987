import React from "react";
import { Header, Table, Input, Responsive, Grid } from "semantic-ui-react";

const integerCheck = /^[1-9]\d*$/;

const prices = [
  [{ min: 2, max: 9, price: 240.0 }, { min: 10, max: 99, price: 210.0 }],
  [{ min: 100, max: 10000, price: 180.0 }, { min: 500, price: 180.0 }],
  
];

export default class School extends React.Component {
  state = {
    value: "",
    price: 0,
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      products: nextProps.product.reduce((acc, current, index) => {
        if (index % 2 === 0) acc.push([current]);
        else acc[acc.length - 1].push(current);
        return acc;
      }, []),
    };
  }
  inputChangedHandler = (event) => {
    const { value } = event.target;
    if (integerCheck.test(value) || value === "") {
      const priceData = prices
        .flat()
        .find(
          (priceData) =>
            priceData.min <= +value &&
            (!priceData.max || priceData.max >= +value)
        );
      this.setState({
        value,
        price:
          value === "1"
            ? 0
            : (priceData && (priceData.price * value).toFixed(2)) || 0,
      });
    }
  };
  renderInput = (props) => (
    <Input
      label={{ basic: true, content:  this.state.price + " TL"}}
      labelPosition="right"
      type="tel"
      fluid
      onChange={this.inputChangedHandler}
      value={this.state.value}
      placeholder="Kullanıcı Sayısı"
      {...props}
    />
  );
  render() {
    let { value, showError } = this.state;
    let { checkout } = this.props;
    return (
      <>
        <p>
          Ödeme yöntemi olarak <b>sadece kredi kartı</b> ya da{" "}
          <b>banka kartı</b> ile ödeme alınmaktadır. Sanal pos 128 bit güvenli
          sertifika ile şifrelenmektedir ve kart bilgileriniz saklanmamaktadır.
          “Satın Al” butonuna basarak ödeme sayfasına gidebilirsiniz.
        </p>
        <Table celled textAlign="center" unstackable>
          <Table.Body>
            {this.state.products.map((pricePair, key) => (
              <Table.Row key={key}>
                {pricePair.map((priceData) => (
                  <Table.Cell key={priceData.min_user}>
                    {priceData.max_user < 100
                      ? priceData.min_user +
                        " - " +
                        priceData.max_user +
                        " Kullanıcı: "
                      : priceData.max_user == 499 ? "100+ Kullanıcı" : "Kurumunuza Özel Teklif Almak İçin "}
                    {priceData.max_user < 500 ? (
                      <span>
                        {/* <strike style={{ color: "#FF0000" }}>
                          ₺{priceData.price + 30}
                        </strike> */}
                        <b> ₺{priceData.price}</b>
                      </span>
                    ) : (
                      <a href="/faq">
                        <b>Bize Ulaşın</b>
                      </a>
                    )}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Grid stackable centered>
          <Grid.Column width="16">
            <Responsive
              as={React.Fragment}
              maxWidth={Responsive.onlyTablet.maxWidth}
            >
              {this.renderInput({
                actionPosition: "left",
                action: {
                  color: "teal",
                  icon: "cart",
                  onClick: () =>
                    value > 1
                      ? checkout(value)
                      : this.setState({ showError: true }),
                },
              })}
            </Responsive>
            <Responsive as={React.Fragment} {...Responsive.onlyComputer}>
              {this.renderInput({
                actionPosition: "left",
                action: {
                  color: "teal",
                  labelPosition: "left",
                  icon: "cart",
                  content: "Satın Al",
                  onClick: () =>
                    value > 1
                      ? checkout(value)
                      : this.setState({ showError: true }),
                },
              })}
            </Responsive>
          </Grid.Column>
        </Grid>
        {showError && (
          <Header as="h5" color="red">
            *Kullanıcı sayısı belirtilen aralıkta olmalıdır.
          </Header>
        )}
      </>
    );
  }
}
